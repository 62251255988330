import React, { useRef } from "react";
import { Terminal } from "lucide-react";
import useTerminal from "../hooks/useTerminal";
import { renderMarkdown } from "../utils/markdownRenderer";

const TerminalPortfolio = () => {
  const { input, setInput, output, currentPath, handleInput } = useTerminal();
  const inputRef = useRef(null);

  const handleContainerClick = () => {
    inputRef.current.focus();
  };

  return (
    <div
      className="bg-black text-green-500 p-4 font-mono h-screen flex flex-col"
      onClick={handleContainerClick}
    >
      <div className="flex items-center mb-4">
        <Terminal className="mr-2" />
        <h1 className="text-xl font-bold">Ennis Ma Portfolio</h1>
      </div>
      <div id="terminal-output" className="flex-grow overflow-y-auto mb-4">
        {output.map((line, index) => (
          <div key={index} className="whitespace-pre-wrap">
            {typeof line === "string"
              ? line
              : line.markdown
              ? renderMarkdown(line.markdown)
              : line}
          </div>
        ))}
      </div>
      <div className="flex items-center">
        <span className="mr-2">{currentPath} $</span>
        <input
          ref={inputRef}
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleInput}
          className="bg-transparent border-none outline-none flex-grow"
          autoFocus
        />
      </div>
    </div>
  );
};

export default TerminalPortfolio;
