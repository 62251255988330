const resumeContent = {
  full: `
# Yuk Kwan MA (Ennis)

+1 403-305-8680 · ennis214@gmail.com · Calgary, AB

## Professional Summary

Driven and self-motivated Junior Developer with a solid foundation in JavaScript, Python, and modern web
technologies like React, Vue, and GraphQL. Gained hands-on experience leading software development
projects during studies at SAIT, demonstrating strong problem-solving abilities and a keen eye for delivering
high-quality, maintainable code. Passionate about building performant and user-friendly web applications
with a focus on security and best practices. Excellent team player with exceptional communication and
organizational skills. Eager to contribute to innovative projects and continuously expand my skills in a
collaborative environment.

## Professional Experience

### SAP Hong Kong Co. Limited
**Demand Generation Specialist** (Apr 2018 - Aug 2022)
- Organized and co-managed 3-4 marketing events focused on SAP Success Factor and SAP Ariba,
  contributing to lead generation for these key product lines.
- Cultivated relationships with C-level executives and potential clients, identifying their business needs
  and proactively suggesting how SAP solutions could provide value.
- Collaborated with marketing, sales, and global/regional teams to deliver localized sales strategies and
  reporting, demonstrating strong communication and cross-functional teamwork abilities.

### Hustle Media Limited
**Project Manager** (Sept 2017 - Apr 2018)
- Partnered with the Taipei City Government to build an inclusive city website, developing a map and blog
  platform to promote accessibility and inclusiveness.
- Led the development of e-commerce websites, mobile apps, and social media marketing campaigns for
  clients in Hong Kong and Taiwan.
- Collaborated with external partners to achieve project goals, demonstrating effective teamwork and
  resource management.

### Infoware System Limited
**Sales Support Engineer** (Jan 2015 - Nov 2015)
- Provided comprehensive pre-sales and post-deployment support, including client communication, pitch
  deck preparation, technical troubleshooting, and on-site assistance during system implementation.
- Collaborated with development teams to debug software and integrate various technology solutions
  (CCTV, eBooking, CRM, iPOS), demonstrating technical proficiency and the ability to work cross-
  functionally.
- Assisted project managers with planning, monitoring, and successful execution of projects, gaining
  exposure to essential project management processes.

## Education

- Southern Alberta Institute of Technology
  Diploma of Software Development (GPA 4.0)

- Hong Kong Institute of Vocational Education
  Higher Diploma of Network Applications

## Technical Skills

- Programming: Python, JavaScript, TypeScript (React, Vue), C#, Flutter
- Databases: MongoDB, MySQL, PostgreSQL
- Web Technologies: REST API, GraphQL
- Cloud Solution: Firebase
- Tools & Technologies: Docker

# Projects

# Inclusive City Website for Taipei City Government

- Developed a map and blog platform to promote accessibility and inclusiveness
- Collaborated with external partners to achieve project goals
- Demonstrated effective teamwork and resource management
- Technologies used: PHP

# E-commerce Website

- Led development for clients in Hong Kong and Taiwan
- Features included: Full-featured e-commerce websites with multiple payment gateways, SEO optimization, and blogs
- Technologies used: WordPress
    
# Terminal-style Portfolio Website

- Designed and developed a unique terminal-style portfolio website using React
- Implemented custom command handling and file system simulation
- Showcased proficiency in modern web development techniques and user interface design

# Gym Booking App
   - Created a mobile app for iOS and Android
   - Features included: Booking, payment, and tracking
   - Technologies used: Flutter

`,

  about: `
# About Yuk Kwan MA (Ennis)

I am a driven and self-motivated Junior Developer based in Calgary, AB. With a solid foundation in JavaScript, Python, and modern web technologies, I am passionate about building performant and user-friendly web applications. My focus is on delivering high-quality, maintainable code while adhering to security best practices.

## Education

- Southern Alberta Institute of Technology
  Diploma of Software Development (GPA 4.0)

- Hong Kong Institute of Vocational Education
  Higher Diploma of Network Applications

I gained hands-on experience leading software development projects during my studies at SAIT, which honed my problem-solving abilities and attention to detail. As an excellent team player with exceptional communication and organizational skills, I am eager to contribute to innovative projects and continuously expand my skills in a collaborative environment.
`,

  skills: `
# Technical Skills

- Programming: 
  - Python
  - JavaScript
  - TypeScript (React, Vue)
  - C#
  - Flutter

- Databases:
  - MongoDB
  - MySQL
  - PostgreSQL

- Web Technologies:
  - REST API
  - GraphQL

- Cloud Solution:
  - Firebase

- Tools & Technologies:
  - Docker
`,
  projects: [
    `
# Inclusive City Website for Taipei City Government

- Developed a map and blog platform to promote accessibility and inclusiveness
- Collaborated with external partners to achieve project goals
- Demonstrated effective teamwork and resource management
- Technologies used: PHP

https://friendlystore.taipei/index.php/en
    `,
    `
# E-commerce Websites

- Led development for clients in Hong Kong and Taiwan
- Features included: Full-featured e-commerce websites with multiple payment gateways, SEO optimization, and blogs
- Technologies used: WordPress

http://xn--p8zx3fdxb384b.com/  (I know the url looks sus, because its actully Chinese characters in "punycode")
    `,
    `
# Terminal-style Portfolio Website

- Designed and developed a unique terminal-style portfolio website using React
- Implemented custom command handling and file system simulation
- Showcased proficiency in modern web development techniques and user interface design
    `,
    `
# Gym Booking App
   - Created a mobile app for iOS and Android
   - Features included: Booking, payment, and tracking
   - Technologies used: Flutter
`,
  ],
};

export default resumeContent;
