import React from "react";

export const renderMarkdown = (markdown) => {
  const lines = markdown.split("\n");
  return lines.map((line, index) => {
    if (line.startsWith("# ")) {
      return (
        <h1 key={index} className="text-xl font-bold mt-2 mb-1">
          {line.substring(2)}
        </h1>
      );
    } else if (line.startsWith("## ")) {
      return (
        <h2 key={index} className="text-lg font-bold mt-2 mb-1">
          {line.substring(3)}
        </h2>
      );
    } else if (line.startsWith("### ")) {
      return (
        <h3 key={index} className="text-md font-bold mt-2 mb-1">
          {line.substring(4)}
        </h3>
      );
    } else if (line.startsWith("- ")) {
      return (
        <li key={index} className="ml-4">
          {line.substring(2)}
        </li>
      );
    } else if (line.trim() === "") {
      return <br key={index} />;
    } else {
      const boldRegex = /\*\*(.*?)\*\*/g;
      const parts = line.split(boldRegex);
      return (
        <p key={index}>
          {parts.map((part, i) =>
            i % 2 === 0 ? part : <strong key={i}>{part}</strong>
          )}
        </p>
      );
    }
  });
};
