import resumeContent from "./resumeContent";

export const fileSystem = {
  "~": ["documents", "projects"],
  "~/documents": ["resume.md", "about.md", "skills.md", "projects.md"],
  "~/projects": ["project1.md", "project2.md", "project3.md"],
};

export const fileContents = {
  "~/documents/resume.md": resumeContent.full,
  "~/documents/about.md": resumeContent.about,
  "~/documents/skills.md": resumeContent.skills,
  "~/documents/projects.md": resumeContent.projects.join("\n"),
  "~/projects/project1.md": resumeContent.projects[0],
  "~/projects/project2.md": resumeContent.projects[1],
  "~/projects/project3.md": resumeContent.projects[2],
  "~/projects/project4.md": resumeContent.projects[3],
};
