import { useState, useEffect } from "react";
import { fileSystem, fileContents } from "../data/fileSystem";
import { commands } from "../data/commands";

const useTerminal = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState([
    'Welcome to my portfolio! Type "help" for available commands.',
  ]);
  const [currentPath, setCurrentPath] = useState("~");
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);

  const handleCommand = (cmd) => {
    const [command, ...args] = cmd.split(" ");
    switch (command) {
      case "help":
        return Object.entries(commands)
          .map(([cmd, desc]) => `${cmd}: ${desc}`)
          .join("\n");
      case "ls":
        return fileSystem[currentPath].join("\n");
      case "cd":
        if (args[0] === "..") {
          if (currentPath !== "~") {
            setCurrentPath("~");
            return `Changed directory to ${currentPath}`;
          }
          return "Already at root directory";
        }
        const newPath = `${currentPath}/${args[0]}`;
        if (fileSystem[newPath]) {
          setCurrentPath(newPath);
          return `Changed directory to ${newPath}`;
        }
        return `Directory not found: ${args[0]}`;
      case "cat":
        setOutput([]);
        const filePath = `${currentPath}/${args[0]}`;
        if (fileContents[filePath]) {
          return { markdown: fileContents[filePath] };
        }
        return `File not found: ${args[0]}`;
      case "clear":
        setOutput([]);
        return null;
      default:
        return `Command not found: ${command}. Type "help" for available commands.`;
    }
  };

  const handleInput = (e) => {
    if (e.key === "Enter") {
      const result = handleCommand(input);
      if (result !== null) {
        setOutput((prevOutput) => [
          ...prevOutput,
          `${currentPath} $ ${input}`,
          result,
        ]);
      }
      setCommandHistory((prev) => [...prev, input]);
      setHistoryIndex(-1);
      setInput("");
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (historyIndex < commandHistory.length - 1) {
        setHistoryIndex((prevIndex) => prevIndex + 1);
        setInput(commandHistory[commandHistory.length - 1 - historyIndex - 1]);
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      if (historyIndex > -1) {
        setHistoryIndex((prevIndex) => prevIndex - 1);
        setInput(
          historyIndex === 0
            ? ""
            : commandHistory[commandHistory.length - 1 - historyIndex + 1]
        );
      }
    }
  };

  useEffect(() => {
    const terminal = document.getElementById("terminal-output");
    terminal.scrollTop = terminal.scrollHeight;
  }, [output]);

  return { input, setInput, output, currentPath, handleInput };
};

export default useTerminal;
