import React from "react";
import TerminalPortfolio from "./components/TerminalPortfolio";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <div className="App">
      <Analytics />
      <TerminalPortfolio />
    </div>
  );
}

export default App;
